import * as React from 'react';
import logo from './logo.svg';
import './App.css';

import { RedocStandalone } from 'redoc';

function App() {
  return (<RedocStandalone specUrl="/openapi.yaml" />);
}

export default App;
